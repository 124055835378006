<template>
  <div>
    <div
      v-for="(order, index) in orders"
      :key="order.billno"
      class="macc-order-list order-list-new"
      :class="{ 'fsp-element': index == 0 }"
      :data-datetime="order.addTime"
      :data-address="order.shipping_country_name"
    >
      <div
        @click="
          order.isReadOnly != 1 && order.return_status != 3
            ? $parent.jumpReturnRefundList(order)
            : ''
        "
      >
        <div class="list-title">
          <em v-if="order.return_status != 'null'">
            <i :class="'order-status-' + returnStatusClass[order.return_status]"></i>
            <i class="status_text">{{ returnStatusText[order.return_status] }}</i>
          </em>
          <em
            v-if="order.union_return_tags"
            class="combine_return_icon"
            @click.stop="showCombineReturnTips"
          >
            {{ language.SHEIN_KEY_PWA_23067 }}
            <i class="suiiconfont sui_icon_doubt_16px_2"></i>
          </em>
        </div>
        <div class="list-title list-item-info">
          <span>{{ language.SHEIN_KEY_PWA_16822 }} {{ order.billno }}</span>
        </div>
        <!-- 巴西平台化迁移 -->
        <platFormItem
          :order="order"
          :isOpenShopUrl="isOpenShopUrl"
          :language="language"
        />
      </div>
      <orderListApplication
        v-if="order.returnStatusInfo"
        :order="order"
        :language="language"
        :wait-send-return-order="waitSendReturnOrder"
      />
      <div class="text-capitalize list-bot-price-item-total">
        <span class="list-bot-price-item">
          {{ order.orderGoodsSum }}
          <template v-if="order.orderGoodsSum == 1">
            {{ language.SHEIN_KEY_PWA_14952 }}
          </template>
          <template v-else>
            {{ language.SHEIN_KEY_PWA_15456 }}
          </template>
        </span>
        <span
          class="list-bot-price-total"
        >
          {{ language.SHEIN_KEY_PWA_15231 }}:
          <span class="price">
            {{ order.refund_currency ? order.refund_currency.amountWithSymbol : '' }}
          </span>
        </span>
      </div>
      <div
        v-if="order.return_status != 3"
        class="list-bot"
      >
        <div class="list-bot-btn">
          <button
            class="order-return-item-btn-black order-return-item-btn-s"
            @click="$parent.jumpReturnRefundList(order)"
          >
            {{ language.SHEIN_KEY_PWA_17013 }}
          </button>
        </div>
      </div>
    </div>
    <lazy-mount>
      <s-dialog
        :visible.sync="showCombinedReturn"
        :show-close="false"
      >
        <template slot="title"></template>
        <div v-html="htmlDecode({ text: language.SHEIN_KEY_PWA_23073 })"></div>
        <template slot="footer">
          <s-button
            :type="['primary']"
            :width="'100%'"
            @click="showCombinedReturn = false"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </lazy-mount>
  </div>
</template>

<script>
// 订单列表-退货分组
import platFormItem from './platFormItem.vue'
import orderListApplication from './orderListApplication.vue'
import { mapState, mapGetters } from 'vuex'
import { htmlDecode } from '@shein/common-function'
import { LazyMount } from '@shein/sui-mobile'

export default {
  name: 'OrderReturnItem',
  components: {
    platFormItem,
    orderListApplication,
    LazyMount
  },
  props: {
    orders: {
      type: Array,
      default: function () {
        return []
      }
    },
    isOpenShopUrl: {
      type: Boolean,
      default: true
    },
    waitSendReturnOrder: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      returnStatusClass: {
        0: 'blue',
        1: 'blue',
        2: 'green',
        3: 'grey',
        4: 'green'
      },
      showCombinedReturn: false
    }
  },
  computed: {
    ...mapState('orderList', ['language']),
    ...mapGetters('orderList', ['returnStatusText'])
  },
  methods: {
    htmlDecode,
    showCombineReturnTips() {
      this.showCombinedReturn = true
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-return-item-btn-black {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214s
  background: @color_brand;
  .border-dpr(border, 2px, transparent);
  padding: 0 0.4rem;
  color: #fff;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s, border-color, 0.2s, color 0.2s;
  height: 1.16rem;
  line-height: 1.16rem;
  .font-dpr(32px);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background: fade(@color_brand, 60%);
    color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
  }

  a& {
    text-decoration: none;
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}
.order-return-item-btn-s {
  height: 0.8rem;
  line-height: 0.8rem;
  .font-dpr(24px);
}
.macc-order-list {
  background: #fff;
  margin-top: 16/75rem;
  .free-trial-tag {
    .font-dpr(20px);
    padding: 0.02666rem 0.05333rem;
    border-radius: 1px;
    text-transform: capitalize;
    .margin-l(0.106rem);
  }
  .brithday-gift {
    .font-dpr(20px);
    .margin-l(0.1rem);
    color: #bf9f53;
    .brithday-gift_tag {
      display: inline-block !important; /* stylelint-disable-line declaration-no-important */
      width: 0.28rem;
      height: 0.32rem;
      .margin-r(0.1rem);
      border: none;
      vertical-align: initial;
    }
  }
  .free-gift_tag {
    .margin-l(8/75rem);
    color: @sui_color_promo_dark;
    background: @sui_color_promo_bg;
    .font-dpr(24px);
    font-weight: 400;
    display: inline-block;
    padding: 2/75rem 8/75rem;
  }
  .combined-order {
    color: #666;
    .padding-l(4px);
    i {
      .font-dpr(16px);
    }
  }
  &.order-list-new {
    .list-title {
      font-size: 14px;
      border: 0;
      padding: 0.32rem 0.32rem 0;
      em {
        position: relative;
        display: flex;
        align-items: center;
        .status_text {
          font-weight: bold;
          display: inline-block;
          color: #222;
          .font-dpr(28px);
          margin-right: 0;
        }
        .status-pending {
          .font-dpr(24px);
          color: @sui_color_link;
          // transform: scale(.83);
          display: inline-block;
          // .margin-l(-.3rem);
        }
        .question_icon {
          font-weight: normal;
        }
        .tips_con {
          display: inline-block;
          padding: 0.1rem;
          color: #999;
          border: 1px solid #e5e5e5;
          position: absolute;
          top: -0.1rem;
          .left(100%);
          background: #fff;
          width: 5rem;
          word-break: break-word;
          z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
          .font-dpr(22px);
          font-weight: normal;
        }
      }
      .split-package {
        color: #666666;
        .font-dpr(24px);
      }
    }
    .list-tips {
      margin: 0.16rem 0.32rem 0 0.32rem;
      padding: 0.16rem 0;
      margin-bottom: 0.16rem;
      background-color: #fcf0d4;
      color: #222222;
      display: flex;
      align-items: center;
      .font-dpr(24px);
      span.text {
        flex: 1;
        .padding-l(0.16rem);
        &.no-btn {
          padding: 0 0.16rem;
        }
      }
      span.btn {
        padding: 0 0.16rem;
      }
    }
    .list-item-info {
      .font-dpr(24px);
      color: #666666;
      padding: 8/75rem 0 24/75rem 0;
      margin: 0 0.32rem;
      border-bottom: 1px solid #e5e5e5;
    }
    .list-bot-price-item-total {
      padding: 0.32rem;
      color: #666666;
      .font-dpr(24px);
      .txt-r();
      .list-bot-price-total {
        .price {
          font-weight: 600;
          color: #222222;
        }
      }
    }
    .list-bot {
      padding: 0 0.32rem 0.32rem;
      font-size: 0;
      .direction-r();
      .list-bot-price {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        .list-bot-price-item {
          color: #666;
          margin-right: 10/42.4rem;
        }
        .list-bot-price-total {
          color: #999;
          > span {
            font-size: 14px;
            color: #333;
          }
        }
      }
      .list-bot-btn {
        .txt-r();
        position: relative;
        .padding-l(0.8rem);
        button {
          line-height: 1.15;
          &:first-child {
            background: @color_brand;
            color: #ffffff;
          }
          &:first-child[disabled] {
            background: #999;
            color: #bbb;
            border: none;
            & when (@IS_RW) {
              background: #fec5d4;
              color: #ffeded;
            }
          }
        }
        > button {
          .margin-l(0.32rem);
        }
        > div + button {
          .margin-l(0.32rem);
        }
        > div:first-child {
          button {
            background: @color_brand;
            color: #ffffff;
            // & when (@IS_RW) {
            // 	border-color: transparent!important;/* stylelint-disable-line declaration-no-important */
            // }
          }
        }
        .report-disabled {
          // background: #ffffff!important;/* stylelint-disable-line declaration-no-important */
          // border: 2/75rem solid #BBBBBB!important;/* stylelint-disable-line declaration-no-important */
          // color: rgba(34, 34, 34, .3)!important;/* stylelint-disable-line declaration-no-important */
          background: #999 !important; /* stylelint-disable-line declaration-no-important */
          color: #bbb !important; /* stylelint-disable-line declaration-no-important */
          border: none !important; /* stylelint-disable-line declaration-no-important */
          & when (@IS_RW) {
            background: #fec5d4 !important; /* stylelint-disable-line declaration-no-important */
            color: #ffeded !important; /* stylelint-disable-line declaration-no-important */
          }
        }
        .over-time {
          background-color: rgba(
            34,
            34,
            34,
            0.4
          ) !important; /* stylelint-disable-line declaration-no-important */
          border-color: rgba(
            34,
            34,
            34,
            0.4
          ) !important; /* stylelint-disable-line declaration-no-important */
          & when (@IS_RW) {
            background: #fd8ca9 !important; /* stylelint-disable-line declaration-no-important */
            border-color: #fd8ca9 !important; /* stylelint-disable-line declaration-no-important */
          }
        }
        .opacity-item {
          opacity: 0.3;
          filter: alpha(opacity=30);
        }
        .order-return-item {
          margin-top: 0.2rem;
          display: inline-block;
          vertical-align: middle;
          a {
            height: 26/42.4rem;
            line-height: 26/42.4rem;
            padding: 0 8/42.4rem;
            text-decoration: none;
            display: block;
            color: #222;
          }
        }
        .comment-btn-op {
          position: relative;
          display: inline-block;
          .margin-l(0.32rem);
          .sui_icon_hourglass_12px_2 {
            .font-dpr(28px);
            vertical-align: middle;
          }
          .points-tip-bottom {
            position: absolute;
            background: #ffffff;
            box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.1);
            padding: 0.093rem 0.213rem;
            color: #666;
            text-transform: capitalize;
            top: 0.85rem;
            .right(-0.12rem);
            white-space: nowrap;
            .zindex-translatez(8,translate3d(0,0,8px));
            .font-dpr(24px);
            border: 1px solid #fff;
            [class*='iconfont'] {
              position: absolute;
              .font-dpr(24px);
              transform: scale(0.4);
              .right(-0.05rem);
              top: -0.12rem;
            }
            & when (@IS_RW) {
              background: @sui_color_brand;
              border-color: transparent;
              border-radius: 0.053rem;
              height: 0.53rem;
              line-height: 100%;
              color: #ffffff;
              [class*='iconfont'] {
                top: -0.05rem;
              }
            }
          }
          .points-tip-arrow {
            position: relative;
            display: block;
            width: 0.3rem;
            height: 0.3rem;
            border-bottom: 0.3rem solid #fff;
            border-right: 0.2rem solid transparent;
            border-left: 0.2rem solid transparent;
            border-top: none;
            margin: 0 auto;
            .zindex-translatez(8,translate3d(0,0,8px));
            & when (@IS_RW) {
              border-bottom: 0.3rem solid @sui_color_brand;
            }
          }
          .pay-down-tip-arrow {
            position: relative;
            display: block;
            width: 0.3rem;
            height: 0.3rem;
            border-right: 0.3rem solid transparent;
            border-left: 0.3rem solid transparent;
            border-top: none;
            margin: 0 auto;
            .zindex-translatez(8,translate3d(0,0,8px));
            border-bottom: 0.3rem solid @sui_color_welfare;
          }
        }
        .my-reviewed-comment {
          position: absolute;
          top: 0;
          .left(0);
          a {
            display: inline-block;
            height: 0.8rem;
            text-decoration: none;
            .font-dpr(20px);
            .point {
              width: 0.1rem;
              height: 0.1rem;
              background: rgba(34, 34, 34, 1);
              border-radius: 0.07rem;
              border: 2px solid #222;
              display: inline-block;
            }
          }
          .btn-more-list {
            position: absolute;
            background: #fff;
            padding: 0.1rem 0.3rem;
            color: #666;
            text-transform: capitalize;
            top: 0.8rem;
            .left(-0.12rem);
            white-space: nowrap;
            z-index: 10; /* stylelint-disable-line declaration-property-value-blacklist */
            .font-dpr(24px);
            border: 1px solid #e5e5e5;
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
            .txt-l();
            &::after {
              content: ' ';
              display: block;
              width: 0.3rem;
              height: 0.3rem;
              border: 1px solid #e5e5e5;
              position: absolute;
              .left(0.25rem);
              top: -0.15rem;
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
              background: #fff;
              border-bottom: #fff;
              border-left: #fff;
            }
            .btn-more {
              padding: 0.1rem 0;
            }
          }
        }
        .S-popover__main-content {
          white-space: nowrap;
          padding: 0.093rem 0.13rem;
          background: @sui_color_welfare;
        }
        .expire-count-down-info {
          .S-popover__main {
            display: none;
          }
        }
        .normal-expire-count-down {
          position: relative;
          .S-popover__main {
            margin-top: -24/75rem;
          }
        }
        .S-popover__main-dark::before {
          background: @sui_color_welfare;
        }
        .order-list-unpaid-wrap {
          .direction-l();
          .flexbox();
          position: absolute;
          background: @sui_color_welfare;
          padding: 0.093rem 0.13rem;
          color: #fff;
          text-transform: capitalize;
          top: 1.2rem;
          white-space: nowrap;
          .zindex-translatez(8,translate3d(0,0,8px));
          .font-dpr(24px);
          border-radius: 1px;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
          [class*='iconfont'] {
            color: #ffffff;
          }
        }
      }
    }
    .list-bot-giftcard {
      display: flex;
      align-items: flex-start;
      .cancel-giftcard {
        .margin-r(24/75rem);
        font-weight: normal;
      }
    }
    .list-con {
      padding: 0.32rem 0.32rem 0 0.32rem;
      border: none;
      > li {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .list-con-img {
          display: inline-block;
          width: 2rem;
          margin-right: 8/75rem;
          img {
            max-width: 100%;
            height: 198/75rem;
          }
        }
      }
      &.last-list-con {
        > li {
          border-bottom: none;
        }
      }
    }
    &.giftcard {
      .list-con {
        .list-con-img {
          display: flex;
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
          .inner-left {
            width: 152/75rem;
            height: 94/75rem;
            overflow: hidden;
          }
          .inner-right {
            .margin-l(16/75rem);
            .font-dpr(24px);
            color: #666;
            .price {
              margin-top: 16/75rem;
            }
          }
        }
      }
      .list-bot-price-item-total {
        padding: 0.32rem;
      }
      .list-bot {
        .list-bot-btn {
          .txt-r();
          .padding-l(0);
          button:first-child {
            .margin-l(0);
          }
        }
      }
      .order-list-countdown .pay-down-tip-arrow {
        position: relative;
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border-right: 0.3rem solid transparent;
        border-left: 0.3rem solid transparent;
        border-top: none;
        margin: 0 auto;
        .zindex-translatez(8,translate3d(0,0,8px));
        border-bottom: 0.3rem solid @sui_color_welfare;
      }
    }
  }
  .list-title {
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > span {
      user-select: text;
      .font-dpr(24px);
    }
    > em {
      display: block;
      > i {
        width: auto;
        height: auto;
        .padding-l(8/75rem);
        display: inline-block;
        border-radius: 100%;
        &.order-status-processing,
        &.order-status-returned,
        &.order-status-verified,
        &.order-status-refunded,
        &.order-status-shipped,
        &.order-status-paid,
        &.order-status-green {
          background: @sui_color_safety;
        }
        &.order-status-canceled,
        &.order-status-expired,
        &.order-status-revoked,
        &.order-status-red {
          background: @sui_color_unusual;
          // background: #FAAD14;
          // & when (@IS_RW) {
          // 	background: #FACB5A;
          // }
        }
        &.order-status-waitting,
        &.order-status-verifty,
        &.order-status-blue {
          background: @sui_color_link;
        }
        &.order-status-delivered,
        &.order-status-grey {
          background: @sui_color_gray_light2;
        }
        &:first-child {
          vertical-align: middle;
          padding: 0.08rem;
        }
      }
      &.combine_return_icon {
        display: inline-flex;
        align-items: center;
        color: #666;
        .font-dpr(24px);
        i {
          .font-dpr(24px);
        }
      }
    }
  }

  .list-bot {
    padding: 0.2rem 0.4rem;
    .align-center();

    .text-uppercase {
      .discout-price {
        display: block;
        color: #ff596e;
        white-space: nowrap;
        line-height: 1.5;
      }
    }
  }
  .list-con-more {
    text-align: center;
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > i {
      .margin-l(0.2rem);
      display: inline-block;
      &.up {
        transform: rotateX(180deg);
        transition: all 0.3s ease;
      }
    }
  }
}
</style>
