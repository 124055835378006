<template>
  <div class="order-list-item">
    <slot :pay-now-label-list="payNowLabelList"></slot>
  </div>
</template>
<script>
import { template } from '@shein/common-function'

export default {
  name: 'OrderListItem',
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    order: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    payNowAbtInfo: {
      type: Object,
      default: () => {}
    },
    showPriceGuaranteeAbt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 利诱类型abt实验列表
    testTypeList() {
      const abtTypeInfo = this.payNowAbtInfo.OrderlistInducementType
      if (typeof abtTypeInfo === 'string' && abtTypeInfo) {
        return abtTypeInfo.split(',')
      }
      return []
    },
    // 是否显示免邮信息
    showShippingFree() {
      if (this.testTypeList.includes('2') && this.order.shippingPrice) {
        const amount = this.order.shippingPrice.amount
        return parseFloat(amount) === 0
      }
      return false
    },
    // 显示价保条件
    showPriceGuarantee() {
      const orderGoodsList = this.order.orderGoodsList || []
      return (
        this.testTypeList.includes('4') && 
        this.showPriceGuaranteeAbt &&
        orderGoodsList.some(item => {
          if (Array.isArray(item.goods_sn_relation_goods_list)) {
            return item.goods_sn_relation_goods_list.some(child => child.price_tag == 1)
          } else {
            return item.price_tag == 1
          }
        })
      )
    },
    // 开启利诱实验
    orderListPromotionTest() {
      return ['on1', 'on2'].includes(this.payNowAbtInfo.OrderlistPromotionTest)
    },
    // 未支付
    isUnPaidOrder () {
      return [0, 12].includes(+this.order.orderStatus)
    },
    // 利诱列表
    payNowLabelList() {
      const labelList = []
      const virtualDetail = [5, 6].includes(+this.order.order_type)
      // 非未支付、未配置诱样式的、未配置利诱类型的不展示利诱信息
      if (virtualDetail || !this.isUnPaidOrder || !this.orderListPromotionTest || !this.testTypeList.length) {
        return labelList
      }
      // 1-订单优惠信息
      if ((this.testTypeList.includes('1') && this.order.total_discount_new?.amount > 0)) {
        labelList.push({
          type: 'discount',
          name: 'sui_icon_coupon_discount_14px',
          inducementType: '1',
          text: template(
            this.order.total_discount_new.amountWithSymbol,
            this.language.SHEIN_KEY_PWA_30862
          )
        })
      }
      // 2-免邮信息
      if (this.showShippingFree) {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_shipping_14px',
          inducementType: '2',
          text: this.language.SHEIN_KEY_PWA_30865
        })
      }
      // 3-免费退信息
      if (this.testTypeList.includes('3') && this.order.isFreeRefund) {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_return_14px',
          inducementType: '3',
          text: this.language.SHEIN_KEY_PWA_30863
        })
      }
      // 4-价保信息
      if (this.showPriceGuarantee) {
        labelList.push({
          type: 'success',
          name: 'sui_icon_guarantee_price_green_14px',
          inducementType: '4',
          text: this.language.SHEIN_KEY_PWA_30864
        })
      }
      labelList.sort((a, b) => {
        return this.testTypeList.indexOf(a.inducementType) - this.testTypeList.indexOf(b.inducementType) 
      })
      return labelList
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.adjuctOrderListMore()
    })
  },
  updated () {
    this.$nextTick(() => {
      this.adjuctOrderListMore()
    })
  },
  methods: {
    // 判断按钮列表一行是否放得下
    adjuctOrderListMore() {
      this.orders?.forEach(item => {
        let btnMoreList = item.btnMoreList || []
        const listBtnBillno = $(`#list-btn-${item.billno}`)
        const boxWidth = listBtnBillno?.width()
        const buttonList = listBtnBillno?.find('button.order-list-bot__btn')
        let buttonWidth = 0
        if (boxWidth > 0) {
          let overIndex = 0
          buttonList?.each((i, n) => {
            const obj = $(n)
            const objParent = obj?.parent()
            const objTop = objParent?.hasClass('comment-btn-op') ? objParent : obj
            buttonWidth = buttonWidth + objTop?.width()
            if (buttonWidth + 80 > boxWidth) {
              overIndex = i
            }
          })
          if (overIndex > 0) {
            this.$set(item, 'isShowBtnMore', true)
            buttonList?.each((i, n) => {
              const obj = $(n)
              const objParent = obj?.parent()
              let addClass = ''
              const objTop = objParent?.hasClass('comment-btn-op') ? objParent : obj
              if (obj.hasClass('opacity-item')) {
                addClass = 'opacity-item'
              }
              if (i >= overIndex) {
                btnMoreList.push({
                  el: obj,
                  text: obj.html(),
                  addClass: addClass
                })
                objTop?.remove()
              }
            })
          }
        }
        if (btnMoreList?.length) {
          this.$set(item, 'btnMoreList', btnMoreList)
        }
      })
    },
  },
}
</script>
