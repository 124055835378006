<template>
  <div class="orderList-tip-box">
    <common-notice
      class="delay-track-text"
      :message="delayTrackText"
    />
  </div>
</template>
<script>
import commonNotice from '../../../../common/commonNotice/index.vue'

export default {
  name: 'ListTips',
  components: {
    commonNotice,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    delayTrackText: {
      type: String,
      default: ''
    },
  },
}
</script>
<style lang="less">
.orderList-tip-box {
  .delay-track-text {
    word-break: break-word;
  }
}
</style>
