<script>
import vue from 'vue'
import { Popover } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp/index'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })
vue.use(Popover)

export default {
  props: {
    langText: { 
      type: Object, 
      default: () => ({}) 
    }, 
    abt: { 
      type: String, 
      default: '' 
    },
  },
  data: () => ({
    relationAcc: { show: false, toast: false }
  }),
  mounted () {
    this.queryRelationAccount()
  },
  methods: {
    async handleQueryCheck () {
      daEventCenter.triggerNotice({ 
        daId: '1-20-1-50',
        extraData: {
          type: 'origin'
        }
      })
      const data = await this.queryRelationAccountSer({ cache: '0' })

      const { code, tips, info } = data

      if (code == 0 && info.exists_relation_account == '1') {
        const list = info.accounts || []
        const actionCb = ({} = {}) => {}
        const action_val = {  list,  abt: this.abt, cb: actionCb, from: 'order_list' }
        const loginCb = () => {
          location.reload()
        }
        const vparams = { show: true, cb: loginCb, instance: 'relationOrder', action: 'relation_account', action_val, bi: 'orderlist_related_account' || 'other' }
        SHEIN_LOGIN.show(vparams)
        return
      }
      this.$toast(tips || this.langText.SHEIN_KEY_PWA_14899)
    },
    async queryRelationAccountSer (params) {
      let res = await schttp({
        method: 'POST',
        url: '/api/auth/relation/query',
        data: params
      })
      return res || {}
    },
    handleCloseRelationToast() {
      daEventCenter.triggerNotice({ daId: '1-20-1-52' })
      this.relationAcc.toast = false
    },
    getLocalStoreItem () {
      let v = {}
      try {
        v = window.localStorage.getItem('order_list_relation_toast_times')
        v = JSON.parse(v) || { times: 0, close: 0 }
      } catch (e) {
        v = { times: 0, close: 0 }
      }
      return v
    },
    setLocalStoreItem (v) {
      try {
        window.localStorage.setItem('order_list_relation_toast_times', JSON.stringify(v))
      } catch (e) { console.log(e) }
    },
    // 查询关联账号
    async queryRelationAccount() {
      this.relationAcc.show = true
            
      daEventCenter.triggerNotice({ 
        daId: '1-20-1-49',
        extraData: {
          type: 'origin'
        }
      })

      const v = this.getLocalStoreItem()

      // 展示次数大于3次，不展示气泡提示
      if (v.times >= 3) {
        return
      }

      // 关闭过，且关闭时间小于24小时，不展示气泡提示
      if (v.close && Date.now() - v.close < 24 * 60 * 60 * 1000) {
        return
      }

      setTimeout(() => {
        daEventCenter.triggerNotice({ daId: '1-20-1-51' })
        this.relationAcc.toast = true
        v.times++
        v.close = Date.now()
        this.setLocalStoreItem(v)
      }, 500)
    },
  }
}
</script>

<template>
  <div></div>
  <!-- <div
    v-show="relationAcc.show"
    class="page__order_list-relactionToast"
  >
    <s-popover
      v-model="relationAcc.toast"
      trigger-type="user"
      placemen="left"
      :append-to-body="false"
      :show-close-icon="true"
      @handleCloseIconClick="handleCloseRelationToast"
    >
      <span
        slot="reference"
        class="find"
        @click="handleQueryCheck"
      ><i class="suiiconfont sui_icon_orders_find_24px"></i></span>
      <p class="page__order_list-relactionPopover">
        {{ langText.SHEIN_KEY_PWA_21296 }}
      </p>
    </s-popover>
  </div> -->
</template>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.page__order_list-relactionToast {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(34, 34, 34, 0.3);
    .right(14px);
    bottom: 40px;
    .S-popover {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    p {
        // position: absolute;
        // background: rgba(0, 0, 0, 0.8);
        // border-radius: 2px;
        // white-space: nowrap;
        // transform: translateX(-100%);
        color: #FFFFFF;
        .font-dpr(24px);
        // padding: 12px;
        // .padding-r(16px);
        // .left(-11px);
    }
    .find {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .S-popover__main-content {
        white-space: nowrap;
        // background: rgba(0, 0, 0, 0.8);
    }
}
.page__order_list-relactionPopover {
    padding: 6px;
}
</style>
