<template>
  <s-button
    class="pay-now-btn"
    :type="['primary', 'H56PX']"
    :class="{
      'promotion-one': orderListPromotionTest == 'on1',
      'promotion-two': orderListPromotionTest == 'on2',
    }"
    @click="onClick"
  >
    <span
      ref="payNowBtn"
      class="pay-now-btn__text"
    >
      <Icon
        v-if="orderListPromotionTest == 'on2' && payNowLabelList.length"
        :name="payNowLabel.name"
        size="14px"
      />
      <slot></slot>
      <order-expire-time-down
        v-if="showExpireCountDown"
        class="pay-now-btn__countdown"
        :show-icon="false"
        :order="order"
      />
    </span>
    <div class="pay-now-btn__wrap not-fsp-element">
      <div
        v-if="orderListPromotionTest == 'on2'"
        class="pay-now-btn__flash"
        :class="{ 'show-flash': showFlash }"
        @transitionend="onTransitionend"
      ></div>
      <div 
        v-if="orderListPromotionTest == 'on1' || startStrip"
        class="pay-now-btn__strip"
        :class="{
          'is-ar': GB_cssRight
        }"
      >
      </div>
    </div>
    <div 
      v-if="orderListPromotionTest == 'on1' && payNowLabelList.length"
      class="not-fsp-element pay-now-btn__footer"
    >
      <PayNowArrow
        :order="order"
        :language="language"
        :labelItem="payNowLabel"
      />
    </div>
  </s-button>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { getCookie } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue2'
import orderExpireTimeDown from '../orderExpireTimeDown.vue'
import PayNowArrow from './PayNowArrow.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const { GB_cssRight } = gbCommonInfo

const emit = defineEmits(['click', 'update-free-refund'])
const props = defineProps({
  order: {
    type: Object,
    default: () => {}
  },
  abtInfo: {
    type: Object,
    default: () => {}
  },
  orderListAbtInfo: {
    type: Array,
    default: () => []
  },
  language: {
    type: Object,
    default: () => {}
  },
  payNowLabelList: {
    type: Array,
    default: () => []
  },
})

const payNowBtn = ref()
const showFlash = ref(false)
const isFreeRefund = ref(false)
const startStrip = ref(false)

const showExpireCountDown = computed(() => {
  const { orderStatus, order_expire_time, expireCountdown, _selfTime } = props.order || {}
  const isRangeTime = Number(_selfTime) < Number(order_expire_time || expireCountdown)
  return (
    [0, 12, 13].includes(orderStatus) &&
    props.orderListAbtInfo.includes('paytime=on') &&
    isRangeTime
  )
})

// 利诱类型abt实验列表
const testTypeList = computed(() => {
  const abtTypeInfo = props.abtInfo.OrderlistInducementType
  if (typeof abtTypeInfo === 'string' && abtTypeInfo) {
    return abtTypeInfo.split(',')
  }
  return []
})

const orderListPromotionTest = computed(() => {
  return props.abtInfo.OrderlistPromotionTest || ''
})

// 是否显示免费退
const isShowFreeRefund = computed(() => {
  return testTypeList.value.includes('3')
})

const payNowLabel = computed(() => {
  return props.payNowLabelList[0] || {}
})

const onTransitionend = () => {
  startStrip.value = true
}

const handleExposeAnalysis =  () => {
  if (!props.payNowLabelList.length) return
  const labelMap = {
    1: 'save',
    2: 'freeShipping',
    3: 'freeReturn',
    4: 'priceGuarantee'
  }
  const labelList = props.payNowLabelList.map(item => {
    return labelMap[item.inducementType] || ''
  })
  daEventCenter.triggerNotice({
    daId: '1-20-1-126',
    extraData: {
      order_no: props.order.billno,
      inducement_type: labelList.join(',') 
    }
  })
}

// 监听PayNow按钮是否在可见范围内
const observerBtn = () => {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // 请求校验是否免费退接口
        if (isShowFreeRefund.value) {
          getGoodsReturnAndFreeReturn()
        } else {
          handleExposeAnalysis()
        }
        observer.unobserve(payNowBtn.value)
        // 开启背景色切换动画
        showFlash.value = true
      }
    })
  })

  observer.observe(payNowBtn.value)
}

// 查询商品是否可退以及是否免费退
const getGoodsReturnAndFreeReturn = async () => {
  const orderItem = props.order || {}
  if (
    ![0, 12, 13].includes(+orderItem.orderStatus) ||
    orderItem.order_type != 1
  ) {
    return []
  }
  let billNoList = []
  if (Array.isArray(orderItem.sub_order_list)) {
    // 每个U单传前20个子订单
    billNoList = orderItem.sub_order_list.map(item => item.billno).slice(0, 20)
  } else {
    billNoList = [orderItem.billno]
  }
  const res = await schttp({
    method: 'POST',
    url: '/api/orders/base/validGoodsReturnAndFreeReturn/query',
    data: {
      billNoList: billNoList,
      countryId: orderItem.country_id,
      memberId: getCookie('memberId'),
      siteId: gbCommonInfo.IS_RW ? 9 : 7
    }
  })
  if (res?.code == '0' && res?.info) {
    const returnResponseList = res.info.returnResponseList || []
    // 是否满足免费退条件
    isFreeRefund.value = returnResponseList.every(item => {
      const list = item.goodsInfoList || []
      return item.orderReturnable == 1 && list.every(i => i.returnable == 1)
    })
    emit('update-free-refund', {
      isFreeRefund: isFreeRefund.value,
      billno: orderItem.billno
    })
    setTimeout(() => {
      handleExposeAnalysis()
    }, 100)
  }
}

const onClick = event => {
  emit('click', event)
}

onMounted(() => {
  observerBtn()
})
</script>

<style lang="less" scoped>
// 擦亮动画
@keyframes strip-move {
  0% {
    left: 0;
  }
  25% {
    left: calc(100% + 0.2667rem);
  }
  100% {
    left: calc(100% + 0.2667rem);
  }
}

@keyframes strip-move-rtl {
  0% {
    right: 0;
  }
  25% {
    right: calc(100% + 0.2667rem);
  }
  100% {
    right: calc(100% + 0.2667rem);
  }
}

.pay-now-btn {
  position: relative;
  direction: ltr !important;

  &.promotion-one {
    padding: 0 60/75rem;
    overflow: initial;
  }

  &.promotion-two {
    border: none;
  }

  &__footer {
    position: absolute;
    top: 56/75rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }

  &__wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__text {
    position: relative;
    z-index: 5;
    font-size: 16px;
    color: @sui_color_white;
    text-transform: capitalize;
  }

  &__countdown {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
  }

  &__flash {
    position: absolute;
    left: 0;
    top: 0;
    width: 120%;
    height: calc(100% + 2px);
    z-index: 0;
    pointer-events: none;
    transform: translateX(-120%);
    transition: transform ease 1s;

    &.show-flash {
      transform: translateX(-10%);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: skew(-20deg);
      background-color: @sui_color_discount;
    }
  }

  &__strip {
    content: ' ';
    display: block;
    width: 6px;
    height: calc(100% + 10px);
    position: absolute;
    top: -4px;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      95deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    );
    transform: rotate(20deg);
    animation: strip-move 2.5s infinite;

    &.is-ar {
      animation: strip-move-rtl 2.5s infinite; 
    }
  }
}
</style>
