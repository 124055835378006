<template>
  <div
    v-if="isShowCard || findOrderAbt?.FindOrderSwitch == 'on'"
    v-expose="{
      id: '1-20-1-123',
      data: {
        page_type: isOrderSearch
          ? emptyOrder
            ? ' page_order_search_empty'
            : 'page_order_search_end'
          : emptyOrder
            ? 'page_order_list_empty'
            : 'page_order_list_end'
      }
    }"
    class="findorder-wrapper"
  >
    <div class="findorder-title1">{{ language?.SHEIN_KEY_PWA_21296 }}</div>
    <div class="findorder-title2wapper">
      <div v-if="isShowCard">
        <RelationAccountCard
          v-if="isShowCard"
          ref="relation1"
          :langText="language"
          :empty-order="emptyOrder"
          :abt="abt"
        />
        <RelationAcountToast
          v-if="isShowTost"
          ref="relation2"
          :langText="language"
          :abt="abt"
        />

        <div
          class="findorder-title2wapperbtn"
          @click="ralationAccount"
        >
          <span class="findbtn-text">
            {{ language?.SHEIN_KEY_PWA_29926 }}
          </span>
          <Icon
            :name="GB_cssRight?'sui_icon_more_left_16px_2':'sui_icon_more_right_16px_2'"
            size="16px"
          />
        </div>
      </div>
      <div
        v-if="findOrderAbt?.FindOrderSwitch == 'on'"
        class="findorder-title2wapperbtn"
        @click="toserverFind"
      >
        <span class="findbtn-text">
          {{ language?.SHEIN_KEY_PWA_29927 }}
        </span>
        <Icon
          :name="GB_cssRight?'sui_icon_more_left_16px_2':'sui_icon_more_right_16px_2'"
          size="16px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import RelationAccountCard from '../list/RelationAccountCard.vue'
import RelationAcountToast from '../list/relation_account_toast.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose } from 'public/src/pages/common/analysis/directive.js'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })

const { GB_cssRight } = gbCommonInfo
export default {
  components: {
    RelationAccountCard,
    RelationAcountToast,
    Icon
  },
  directives: { expose },
  props: [
    'language',
    'isShowCard',
    'isShowTost',
    'emptyOrder',
    'abt',
    'findOrderAbt',
    'isOrderSearch'
  ],
  data() {
    return {
      GB_cssRight
    }
  },
  methods: {
    toserverFind() {
      this.reportDa('1-20-1-124', {
        page_type: this.isOrderSearch
          ? this.emptyOrder
            ? ' page_order_search_empty'
            : 'page_order_search_end'
          : this.emptyOrder
            ? 'page_order_list_empty'
            : 'page_order_list_end'
      })
      this.$router.push({
        name: 'findorder',
        params: {
          abt: this.findOrderAbt
        }
      })
    },
    reportDa(id, parmas) {
      daEventCenter?.triggerNotice({
        daId: id,
        extraData: {
          ...parmas
        }
      })
    },
    ralationAccount() {
      this.reportDa('1-20-1-125', {
        page_type: this.isOrderSearch
          ? this.emptyOrder
            ? ' page_order_search_empty'
            : 'page_order_search_end'
          : this.emptyOrder
            ? 'page_order_list_empty'
            : 'page_order_list_end'
      })
      if (this.isShowCard) {
        this.$refs?.['relation1']?.handleQueryCheck()
      }
      if (this.isShowTost) {
        this.$refs?.['relation2']?.handleQueryCheck()
      }
    }
  }
}
</script>

<style scoped lang="less">
.findorder-wrapper {
  margin-top: 24/75rem;
  display: flex;
  flex-direction: column;
  padding: 40/75rem 24/75rem;
  background: #fff;
}
.findorder-title1 {
  text-align: center;
  width: 100%;
  color: var(---sui_color_main, #222);
  .font-dpr(28px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.002px;
  margin-bottom: 32/75rem;
}
.findorder-title2wapper {
  display: flex;
  flex-direction: column;
  gap: 24/75rem;
}
.findorder-title2wapperbtn {
  border: 1px solid var(---sui_color_gray_weak1, #e5e5e5);
  padding: 12px;
  display: flex;
  justify-content: space-between;
}
.findbtn-text {
  color: var(---sui_color_gray_dark1, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
