<template>
  <!-- <div
    class="relation-account-block"
    :class="{ 'is-empty-order': emptyOrder }"
  >
    <div
      class="relation-account-card"
      @click="handleQueryCheck"
    >
      <div class="relation-account-card__left">
        <div class="relation-account-card__header">
          <i class="suiiconfont sui_icon_caution_18px"></i>
          <h4 class="relation-account-card__title">
            {{ language.SHEIN_KEY_PWA_22643 }}
          </h4>
        </div>
        <p class="relation-account-card__content">
          {{ language.SHEIN_KEY_PWA_22644 }}
        </p>
      </div>
      <div class="relation-account-card__right">
        <i class="suiiconfont sui_icon_more_right_12px"></i>
      </div>
    </div>
  </div> -->
  <div></div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp/index'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default {
  name: 'RelationAccountCard',
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    emptyOrder: {
      type: Boolean,
      default: false
    },
    abt: {
      type: String,
      default: ''
    }
  },
  mounted() {
    daEventCenter.triggerNotice({
      daId: '1-20-1-49',
      extraData: {
        type: this.emptyOrder ? 'new_null' : 'new'
      }
    })
  },
  methods: {
    async handleQueryCheck() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-50',
        extraData: {
          type: this.emptyOrder ? 'new_null' : 'new'
        }
      })
      const data = await this.queryRelationAccountSer({ cache: '0' })

      const { code, tips, info } = data

      if (code == 0 && info.exists_relation_account == '1') {
        const list = info.accounts || []
        const actionCb = ({} = {}) => {}
        const action_val = {
          list,
          abt: this.abt,
          cb: actionCb,
          from: 'order_list'
        }
        const loginCb = () => {
          location.reload()
        }
        const vparams = {
          show: true,
          cb: loginCb,
          instance: 'relationOrder',
          action: 'relation_account',
          action_val,
          bi: 'orderlist_related_account'
        }
        SHEIN_LOGIN.show(vparams)
        return
      }
      this.$toast(tips || this.language.SHEIN_KEY_PWA_14899)
    },
    async queryRelationAccountSer(params) {
      let res = await schttp({
        method: 'POST',
        url: '/api/auth/relation/query',
        data: params
      })
      return res || {}
    }
  },
}
</script>

<style lang="less">
.relation-account-block {
  padding: 32/75rem 24/75rem;

  &.is-empty-order {
    padding: 48/75rem 0 0;
  }
}

.relation-account-card {
  display: flex;
  align-items: center;
  padding: 28/75rem 24/75rem;
  text-align: left;
  color: @sui_color_gray_dark1;
  background: #fff;

  &__left {
    flex: 1;
    overflow: hidden;
    margin-right: 16/75rem;
  }

  &__right {
    flex-shrink: 0;
    [class*='sui_icon_more_right'] {
      color: @sui_color_gray_dark3;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    line-height: 18px;
    margin-bottom: 12/75rem;
  }

  &__title {
    font-family: 'SF UI Display';
    font-weight: 700;
    font-size: 14px;
    margin-left: 8/75rem;
  }

  &__content {
    font-size: 13px;
    line-height: 16px;
  }
}
</style>
