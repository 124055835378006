<template>
  <!-- 下单24小时倒计时 -->
  <div 
    v-if="timeh> 0 || timem > 0 || times > 0"
    class="count-down-info not-fsp-element"
  >
    <i 
      v-if="showIcon"
      class="suiiconfont sui_icon_hourglass_12px_2"
    >
    </i>
    <span>{{ timeh }} : {{ timem }} : {{ times }}</span>
  </div>
</template>

<script>
export default {
  name: 'OrderExpireTimeDown',
  props: {
    order: {
      type: Object,
      default() {
        return {}
      }
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      timeh: '',
      timem: '',
      times: '',
      timeCountDown: null
    }
  },
  mounted() {
    /* 未支付订单倒计时 */
    let { addTime, order_expire_time, expireCountdown } = this.order || {}
    let countDownData = {
      addTime, // 下单时间为秒钟
      endTime: Number(order_expire_time || expireCountdown)
    }
    this.orderCountDown(countDownData, Date.parse(new Date()) / 1000)
    this.timeCountDown = setInterval(() => {
      this.orderCountDown(countDownData, Date.parse(new Date()) / 1000)
    }, 1000)
  },
  methods: {
    toDouble(num) {
      return `${num}`.length > 1 ? num : `0${num}`
    },
    orderCountDown({ addTime, endTime } = {}, selfTime) {
      if (addTime < selfTime && selfTime < endTime) {
        let intervalTime = endTime - selfTime
        let houst = Math.floor(intervalTime / 3600)
        intervalTime = Math.floor(intervalTime % 3600)
        let minutes = Math.floor(intervalTime / 60)
        let seconds = Math.floor(intervalTime % 60)
        this.timeh = this.toDouble(houst)
        this.timem = this.toDouble(minutes)
        this.times = this.toDouble(seconds)
      } else {
        clearInterval(this.timeCountDown)
        this.times = '00'
      }
    }
  }
}
</script>
